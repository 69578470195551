'use client'

import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames/bind'

import { noop } from '../../utils/noop'
import { useDrag } from '../../hooks'

import CarouselNavigation, { Styling as NavigationStyling } from './CarouselNavigation'

import styles from './Carousel.scss'
import CarouselArrow from './CarouselArrow'
import { nextIndex, previousIndex } from './utils'
import { KeyboardKey } from '../../constants/keyboard'

enum Styling {
  Floating = 'floating',
}

enum Arrows {
  Inside = 'inside',
  Outside = 'outside',
  Hidden = 'hidden',
}

const CONTENT_DRAG_THRESHOLD = 0.2

const carouselStylingMapToNavigation: Record<Styling, NavigationStyling> = {
  [Styling.Floating]: NavigationStyling.Floating,
}

type Props = {
  /**
   * If it's passed, then slides are controlled externally and show only passed index
   */
  index?: number
  slides: Array<ReactNode>
  /**
   * Does scrolling right on the last item puts you back to the first item
   */
  isInfinite?: boolean
  hideNavigation?: boolean
  navigationWithKeyboard?: boolean
  /**
   * Default - Navigation is positioned on top of content<br />
   * Floating - Navigation is positioned below content<br />
   */
  styling?: Styling | `${Styling}`
  arrows?: Arrows | `${Arrows}`
  onSlideInteract?: (index: number) => void
  testId?: string
  isArrowsWideExperimental?: boolean
}

const cssClasses = classNames.bind(styles)

// scroll-behavior: 'smooth' is supported but broken in Safari 15.4+
// when parent container has overflow: hidden
// The polyfills are not an option, because Safari has scroll-behaviour, just broken
// https://developer.apple.com/forums/thread/703294
// https://bugs.webkit.org/show_bug.cgi?id=238497
const isSafari =
  typeof window !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const Carousel = ({
  index: externalIndex,
  slides,
  isInfinite = false,
  hideNavigation,
  styling,
  arrows = Arrows.Inside,
  onSlideInteract = noop,
  testId,
  navigationWithKeyboard,
  isArrowsWideExperimental,
}: Props) => {
  const [internalIndex, setInternalIndex] = useState(0)
  const carouselContent = useRef<HTMLUListElement | null>(null)

  const isFirstRender = useRef(true)
  const dragStartPosition = useRef<number | undefined>()
  const onSlideInteractRef = useRef(onSlideInteract)
  onSlideInteractRef.current = onSlideInteract

  const carouselClass = cssClasses(styles.carousel, styling, {
    outside: arrows === Arrows.Outside,
    'outside-wide': isArrowsWideExperimental,
  })
  const activeIndex = externalIndex ?? internalIndex
  const isInternalControlled = externalIndex === undefined

  const isDragging = useDrag(
    carouselContent.current,
    useCallback(
      ({ horizontalChange }) => {
        if (!carouselContent.current) return

        carouselContent.current.scrollBy({ left: -horizontalChange })
      },
      [carouselContent],
    ),
  )

  const handleSlideChange = useCallback(
    (newIndex: number) => {
      if (isInternalControlled) setInternalIndex(newIndex)

      onSlideInteractRef.current(newIndex)
    },
    [isInternalControlled],
  )

  const scrollToSlide = useCallback(
    (slideIndex: number, scrollType: 'auto' | 'smooth' = 'smooth') => {
      if (!carouselContent.current) return

      const left =
        carouselContent.current.clientWidth * slideIndex - carouselContent.current.scrollLeft

      carouselContent.current.scrollBy({
        left,
        behavior: isSafari ? 'auto' : scrollType,
      })
    },
    [carouselContent],
  )

  useEffect(() => {
    if (!carouselContent.current) return

    const { clientWidth, scrollLeft } = carouselContent.current

    if (isDragging) {
      dragStartPosition.current = scrollLeft

      return
    }

    if (dragStartPosition.current === undefined || !clientWidth) return

    const scrollPosition = scrollLeft / clientWidth
    let newSlideIndex: number

    if (scrollLeft > dragStartPosition.current) {
      newSlideIndex = Math.ceil(scrollPosition - CONTENT_DRAG_THRESHOLD)
    } else {
      newSlideIndex = Math.floor(scrollPosition + CONTENT_DRAG_THRESHOLD)
    }

    dragStartPosition.current = undefined
    handleSlideChange(newSlideIndex)
    scrollToSlide(newSlideIndex)
  }, [carouselContent, handleSlideChange, isDragging, scrollToSlide, slides.length])

  useEffect(() => {
    const scrollType = isFirstRender.current ? 'auto' : 'smooth'

    if (!carouselContent.current) return

    isFirstRender.current = false

    scrollToSlide(activeIndex, scrollType)
  }, [activeIndex, scrollToSlide, carouselContent])

  const handleLeftArrowClick = useCallback(() => {
    handleSlideChange(previousIndex(activeIndex, slides.length, isInfinite))
  }, [activeIndex, handleSlideChange, isInfinite, slides.length])

  const handleRightArrowClick = useCallback(() => {
    handleSlideChange(nextIndex(activeIndex, slides.length, isInfinite))
  }, [activeIndex, handleSlideChange, isInfinite, slides.length])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      switch (event.key) {
        case KeyboardKey.Left:
        case KeyboardKey.ArrowLeft:
          event.preventDefault()
          handleLeftArrowClick()
          break
        case KeyboardKey.Right:
        case KeyboardKey.ArrowRight:
          event.preventDefault()
          handleRightArrowClick()
          break
        default:
          break
      }
    },
    [handleLeftArrowClick, handleRightArrowClick],
  )

  useEffect(() => {
    if (!navigationWithKeyboard) return undefined

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown, navigationWithKeyboard])

  function renderSlide(slide: ReactNode, index: number) {
    return (
      <li key={index} className={styles.content}>
        {slide}
      </li>
    )
  }

  return (
    <section className={carouselClass} data-testid={testId}>
      <ul ref={carouselContent} className={styles['content-container']}>
        {slides.map(renderSlide)}
      </ul>
      {arrows !== Arrows.Hidden && (
        <div role="menu">
          <CarouselArrow side={CarouselArrow.Side.Left} onClick={handleLeftArrowClick} />
          <CarouselArrow side={CarouselArrow.Side.Right} onClick={handleRightArrowClick} />
        </div>
      )}
      {!hideNavigation && (
        <div className={styles['navigation-container']}>
          <CarouselNavigation
            styling={styling ? carouselStylingMapToNavigation[styling] : undefined}
            activeSlide={activeIndex}
            slidesCount={slides.length}
            isInfinite={isInfinite}
            onBulletSelect={handleSlideChange}
          />
        </div>
      )}
    </section>
  )
}

Carousel.Navigation = CarouselNavigation
Carousel.Styling = Styling
Carousel.Arrows = Arrows

export default Carousel
